<template>
  <div>
    <app-datatable
      add-permission="add_shareholder"
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded()"
      @add-new="addNew()"
    >
      <template v-slot:type="slotProp">
        {{ slotProp.item.type[`name_${$i18n.locale}`] }}
      </template>

      <template v-slot:marital_status="slotProp">
        <v-icon v-if="slotProp.item.marital_status == 1">
          mdi-account
        </v-icon>

        <v-icon
          v-else
          color="error"
        >
          mdi-heart
        </v-icon>

        {{ slotProp.item.marital_status == 1 ? $t('Single') : $t('Married') }}
      </template>

      <template v-slot:created_at="slotProp">
        {{ $_date_time_format(slotProp.item.created_at) }}
      </template>

      <!-- Actions -->
      <template
        v-if="$_has_perms(['change_shareholder', 'delete_shareholder'])"
        v-slot:actions="slotProp"
      >
        <v-btn
          small
          depressed
          outlined
          class="my-2"
          @click="viewRow(slotProp.item.id)"
        >
          <v-icon left>
            mdil-eye
          </v-icon>
          {{ $t('View') }}
        </v-btn>

        <v-btn
          color="info"
          small
          depressed
          outlined
          class="my-2 ms-3"
          @click="updateRow(slotProp.item)"
        >
          <v-icon left>
            mdil-pencil
          </v-icon>
          {{ $t('Edit') }}
        </v-btn>
      </template>
    </app-datatable>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="datatable.resetTableData = true"
      @update-table-data="datatable.updateTableData = true"
    />
  </div>
</template>

<script>
import AddEdit from './AddEdit.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    AddEdit
  },

  data() {
    return {
      addEditDialog: false,
      formData: {},
      editMode: false,
      id: 0,
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          { text: this.$t('Full Name'), value: 'fullname', sortable: true },
          { text: this.$t('Nickname'), value: 'nickname', sortable: true },
          { text: this.$t('Type'), value: 'type', sortable: true },
          { text: this.$t('Phone'), value: 'phone', sortable: true },
          { text: this.$t('ID Cart'), value: 'id_cart', sortable: true },
          { text: this.$t('Martial Status'), value: 'marital_status', sortable: false },
          { text: this.$t('Created At'), value: 'created_at', sortable: true },
          { text: this.$t('Actions'), value: 'actions', sortable: false, align: 'center' },
        ],
        tableSlots: [
          'type',
          'marital_status',
          'created_at',
          'actions',
        ]
      },
      endPoint: 'shareholder/'
    }
  },

  mounted() {
    this.$_section_title({ title: 'Shareholders', icon: 'mdil-factory' })
    this.getShareholderTypesList()
  },

  methods: {
    ...mapActions([
      'getShareholderTypesList'
    ]),

    handleDataLoaded() {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
    },

    viewRow(id) {
      this.$router.push({
        name: 'view-shareholder',
        params: { id: id }
      })
    },

    addNew() {
      this.formData = {
        marital_status: 1,
        babies: []
      }
      this.editMode = false
      this.addEditDialog = true
    },

    updateRow(data) {
      this.formData = {
        ...data,
        type: data.type.id,
      }
      this.editMode = true
      this.addEditDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>